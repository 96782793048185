<template>
    <div id="illness-review" style="min-height:90vh">
        <div class="blue-bg">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-md-8 text-center pt-5">
                        <h3 class="text-center mb-5">{{$t("paymentConfirm")}}</h3>

                        <div class="thank2you">
                            <h4 class="text-center h5 pt-2" v-if="Global.quote.productType == Global.enumProductType.PA">
                                <i18n-t tag="div" keypath="policyStartPA"></i18n-t>
                            </h4>
                            <h4 class="text-center h5 pt-2" v-else>
                                <i18n-t tag="div" keypath="policyStart">
                                    <span>
                                        {{policyStartDate.d}}
                                    </span>
                                    <span>
                                        {{policyStartDate.m}}
                                    </span>
                                    <span>
                                        {{policyStartDate.y}}
                                    </span>
                                </i18n-t>
                            </h4>
                        </div>

                        <div class="text-center" style="margin: 3vh auto 0; background-image:url('images/bg-first.png'); background-repeat: no-repeat; background-position: bottom right; min-height:100px;" >
                            <button id="arrow2next" class="btn btn-outline" style="cursor:pointer;" v-if="false">
                                <h2 class="text-default animated2fast bounce2fast">&#8595;</h2>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
import Mixin from '@/components/Mixin';
import Global from "@/store/global.js";

export default {
  name: 'review',
  mixins: [Mixin],
  data(){
      return {
          Global,
          rating : {
              overall : 0,
              website : 0,
              service : 0,
              price : 0
          }
      }
  },
  mounted() {
    this.loading("hide");
    var vm = this;
    window.scrollTo(0, 0);
    //Global.quote.Start_date = "11/06/2021";
  },
  methods: {

  },
  computed:{
    policyStartDate:function(){
        if( !Global.quote.Start_date ){
            return {d:'', m: '', y: ''};
        }

        var dmy =  Global.quote.Start_date.replace(/[\/\-]/g, '-').split('-');
        if( !dmy || dmy.length != 3 ){
            return {d:'', m: '', y: ''};
        }

        return {d:dmy[0], m: dmy[1], y: dmy[2]};
    }
  }
}
</script>
<i18n>
{
  "en": {
    "paymentConfirm": "Thank you for your interest in Roojai!",
    "policyStartPA": "Your policy will start a day after your payment is confirmed. Roojai will send you an email for your policy details. ",
    "policyStart": "Your policy will be start once your payment is confirmed. Roojai will send you an email for your policy details.",
    "shareExpirience": "Share your experience",
    "feedback": "Your feedback",
    "fblogin": "Login with Facebook",
    "fblogout": "Logout",
    "rating": {
        "overall": "Overall rating",
        "website": "Website",
        "callCenter": "Customer service",
        "price": "Product and price"
    },
    "thankForFeedback": "Thank you for your feedback.",
    "submit": "Submit",
    "policyStart_PA_installment": "Thank you for your payment. You are almost done, just one more step. Roojai call centre will contact you to confirm the policy details within 24 hours."
  },
  "id": {
    "paymentConfirm": "Terimakasih atas ketertarikanmu kepada Roojai!",
    "policyStart": "Polis kamu akan dimulai saat pembayaranmu dikonfirmasi. Roojai akan mengirimkan email terkait detail polis. ",
    "policyStartPA": "Polis kamu akan dimulai sehari setelah pembayaranmu dikonfirmasi. Roojai akan mengirimkan email terkait detail polis.",
    "shareExpirience": "Bagikan pengalaman kamu",
    "feedback": "Ulasan kamu",
    "fblogin": "Masuk dengan Facebook",
    "fblogout": "Keluar",
    "rating": {
        "overall": "Penilaian keseluruhan",
        "website": "Website",
        "callCenter": "Pelayanan pelanggan",
        "price": "Produk dan harga"
    },
    "thankForFeedback": "Terimakasih atas masukan yang diberikan.",
    "submit": "Kirim",
    "policyStart_PA_installment": "Terimakasih atas pembayaran yang dilakukan. Kamu hampir selesai dalam satu langkah lagi. Roojai call centre akan menghubungi kamu untuk mengonfirmasi terkait detail polis dalam waktu 24 jam."
  },
}
</i18n>