<template>
    <div id="illness-thankyou" class="py-4 center-container" style="min-height:85vh" data-selenium-name="isTeleselling" :data-selenium-value="Global.isTeleselling ? 'Yes' : 'No'">
        <div class="container">
            <div class="row mb-5">
                <div class="col-12 text-center">
                    <div class="text-center mb-3">
                      {{$t("quotationNo")}} {{Global.quote.opportunityNumber}}
                    </div>

                    <h3 class="text-center">{{$t("thankyou")}}</h3>

                    <div class="mb-4">
                        {{$t("paymentConfirm")}}
                    </div>

                    <div class="text-center">
                        <img class="lazy" src="data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" data-src="images/rj-thank-you.png" />
                    </div>

                    <!-- <div class="mb-4 text-center" v-if="!Global.isTeleselling" style="border:1px solid #ddd; padding:20px; border-radius:5px; background:#f0f5f9; color:#ff5011;">
                        <h4 class="h6 mb-3">
                          <i18n-t tag="div" keypath="oneMoreStep">
                            <div class="mb-3">
                              {{$t("pleaseProvideInfo")}}
                            </div>
                            <div style="font-size:14px; font-weight:normal;">
                              <span style="color:#b9b2b2">|</span> {{$t("address")}} <span style="color:#b9b2b2">|</span> {{$t("beneficiary")}} <span style="color:#b9b2b2">|</span>
                            </div>
                          </i18n-t>
                        </h4>
                    </div> -->
                </div>
            </div>

            <!-- <div class="row justify-content-center mb-4" v-if="!Global.isTeleselling">
                <div class="col-12 col-md-9 col-lg-10">
                    <button type="button" class="btn btn-lg btn-block btn-link-default buy-now py-3" data-selenium-name="nextProcess" @click="go2pd2()">{{$t("continue")}}</button>
                </div>

                <div class="text-center col-12 pt-4">
                  <h4 class="h6 mb-2">{{$t("notReady")}}</h4>
                  <div>
                    {{$t("useEmailLink")}}
                  </div>
                </div>
            </div> -->
        </div>

        <div style="padding-top:10vh">&nbsp;</div>
    </div>
</template>

<script>
import Mixin from "@/components/Mixin";
import Global from "@/store/global.js";

export default {
  name: "thankyou",
  mixins: [Mixin],
  data() {
    return {
      Global
    };
  },
  created() {
    this.loading("hide");
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    go2pd2: function() {
      var vm = this;

      vm.loading("show", 2, function() {
        vm.$router.push(Global.productConfig.route.policydetails2);
      });
    }
  }
};
</script>
<i18n>
{
  "en": {
    "thankyou": "Thank You!",
    "paymentConfirm": "A payment confirmation email was sent to you.",
    "oneMoreStep":"You are just one step away from completing your policy. {0} {1}",
    "pleaseProvideInfo":"Please provide us with the following information :",
    "address":"Address",
    "beneficiary":"Beneficiary",
    "notReady": "If you are not ready",
    "useEmailLink":"You can use the link in the email and fill this information within 24 hours.",
    "continue": "Continue"
  },
  "id": {
    "thankyou": "Terima kasih!",
    "paymentConfirm": "Email konfirmasi pembayaran sudah dikirimkan.",
    "oneMoreStep":"Hanya tinggal satu langkah lagi untuk mendapatkan polis. {0} {1}",
    "pleaseProvideInfo":"Mohon berikan kami informasi berikut :",
    "address":"Alamat",
    "beneficiary":"Ahli waris",
    "notReady": "Jika kamu belum siap",
    "useEmailLink":"Kamu dapat menggunakan link yang tertera dalam email dan isi informasi ini dalam 24 jam.",
    "continue": "Lanjut"
  },
}
</i18n>